import '../../sass/components/widgets/contacts-widget.scoped.scss';
import React from 'react';
import QuickMessageWidget from './QuickMessageWidget';
import CompanyInfoWidget from './CompanyInfoWidget';

const ContactsWidget = ({ className = '' }) => {
  return (
    <div className={`contacts-widget ${className}`}>
      <div className='top'>
        <h5>
          Просто свяжитесь с нами удобным способом и мы сделаем все качественно
          и быстро!
        </h5>
      </div>
      <div className='bottom'>
        <CompanyInfoWidget className={className} />
        <QuickMessageWidget className={className} />
      </div>
    </div>
  );
};

export default ContactsWidget;
