import '../sass/pages/installation.scoped.scss';
import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/blocks/Layout';
import Logo from '../icons/logo.svg';
import ContactsWidget from '../components/widgets/ContactsWidget';
import Finger from '../icons/finger.svg';
import { ModalProvider } from '../context/ModalContext';
import { useModal } from '../context/ModalContext';
import VideoMp4 from '../videos/installation.mp4';
import VideoWebm from '../videos/installation.webm';
import { StaticImage } from "gatsby-plugin-image"
import BgInstallationEl from "../components/backgrounds/bg-installation.el"

const InstallationWrapper = () => {
  return (
    <ModalProvider>
      <Installation />
    </ModalProvider>
  );
};

const Installation = () => {
  const { dispatch } = useModal();
  return (
    <Layout>
      <Seo title='Монтаж' />
      <h1>Монтаж систем вентиляции и кондиционирования</h1>
      <div className='content'>
        <p className='lead'>
          <span>
            ТОО «Invest Group Company» предлагает полный комплекс услуг по{' '}
            <strong>монтажу систем вентиляций и кондиционирования</strong>, от{' '}
            <strong>
              поставки оборудования до сервисного обслуживания систем.{' '}
            </strong>
            . Наши специалисты с радостью ответят на все возникшие у Вас вопросы
            и произведут <strong>качественный монтаж вентиляции.</strong>
          </span>
        </p>
        <section className='text-section'>
          <h2 className='section-heading'>Монтаж системы вентиляции</h2>
          <p>
            Монтаж вентиляции представляет собой сборку элементов вентиляционной
            системы и установку соответствующего оборудования для обеспечения
            циркуляции воздуха в помещении. Качество смонтированной системы
            вентиляции определяет стоимость будущих эксплуатационных затрат и
            обеспечение допустимых и оптимальных параметров микроклимата в
            помещениях. ТОО «Invest Group Company» предлагает клиентам
            выполнение полного спектра работ, от проектирования и подбора
            оборудования до обслуживания смонтированных систем вентиляций.
          </p>
        </section>
        <video className='video' controls>
          <source src={VideoMp4} type='video/mp4' />
          <source src={VideoWebm} type='video/webm' />
        </video>
        <section className='text-section'>
          <h2 className='section-heading'>
            Этапы выполнения монтажа вентиляционных систем
          </h2>
          <p>
            Первым этапом любого проекта является бесплатный выезд специалиста
            на объект и знакомство с клиентом. Далее происходит сбор исходной
            информации, натурные измерения и определение пожеланий клиента, на
            основании которых составляется коммерческое предложение и
            техническое задание.
          </p>
          <p>
            После согласования технического задания специалисты ТОО «Invest
            Group Company» приступают к разработке проектной документации
            системы вентиляции, которая выполняется согласно действующим
            строительным номам Республики Казахстан.
          </p>
          <p>
            Последним этапом является непосредственно монтаж системы вентиляции,
            который условно можно разбить на несколько подпунктов:
          </p>
          <div className='list'>
            <ul>
              <li>
                Подготовительный. Поставка оборудования, воздуховодов, расходных
                элементов.
              </li>
              <li>
                Монтажный. Прокладка вентиляции, сборка воздуховодов и всех
                элементов конструкции.
              </li>
              <li>Проверочный. Выполнение пуско-наладочных работ.</li>
              <li>Финальный. Сдача готовой системы в эксплуатацию.</li>
            </ul>
          </div>
        </section>
      </div>
      <BgInstallationEl
        Tag='section'
        id='installation-contacts'
        className='contacts'>
        <>
          <ContactsWidget className='installation-page-widget' />
          <Logo className='logo' />
        </>
      </BgInstallationEl>
      <div className='content'>
        <section className='text-section'>
          <h2 className='section-heading'>
            Стоимость монтажа система вентиляции и что влияет на цену монтажа
          </h2>
          <p>
            Стоимость монтажа является индивидуальным для каждого проекта и
            сильно варьируется в зависимости от:
          </p>
          <div className='list'>
            <ul>
              <li>Высоты прокладки воздуховодов.</li>
              <li>Сечения воздуховодов.</li>
              <li>Веса и габаритов оборудования.</li>
              <li>Высоты установки оборудования.</li>
              <li>Типа изоляции.</li>
              <li>Количества фасонных изделий.</li>
              <li>
                Условий, в которых производится монтаж (помещение новое или
                реконструкция).
              </li>
            </ul>
          </div>
          <p>
            Свяжитесь с нами удобным для Вас способом и специалисты ТОО «Invest
            Group Company» с радостью ответят на все Ваши вопросы и обсудят с
            Вами время бесплатного выезда специалиста на Ваш объект, после
            которого мы сможем Вам предоставить коммерческое предложение.
          </p>
        </section>
        <div className='actions'>
          <button
            className='btn'
            onClick={() => {
              dispatch({ type: 'open' });
            }}>
            <Finger className='icon-finger' />
            <span>Узнать стоимость</span>
            <span className='decoration'>
              <StaticImage
                className='shake'
                src='../images/leaves/leaf-3.png'
                alt='Лист дерева'
                placeholder='none'
              />
            </span>
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default InstallationWrapper;
